@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap&family=Fira+Code&display=swap&family=Fira+Mono&display=swap');

.projects-container{
}

/* Spotlight Projects Styling */
.spotlight-projects {
    width: 66%;
    display: flex;
    justify-content: space-evenly;
    margin: 2%;
    background-color: #1A2547;
    opacity: 0.8;
}
  

.project-spotlight-preview{
    height: 400px;
    margin-top: 65px;
    margin-bottom: 65px;
    width: 19%;
    
}

.project-spotlight-preview-header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 18px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
    color: #ccd6f6;
    height: 100px;
}

.project-spotlight-preview-description{
    background: linear-gradient(0deg, rgba(55, 0, 103, 0.5) 0%, #09143B 80%);
    width: 100%;
    height: 300px;;
}

.other-projects-table-container {
    display: flex;
    justify-content: flex-end; /* Aligns the child to the right */
}