.minigame-main-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.minigame-instructions{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    padding: 20px;
    width: 350px;
    height: 360px;
    background-color: #1A2547;
    opacity: 0.8;
}

.minigame-instructions h4{
    color: #37FF8B;
    font-size: 16px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
}

.minigame-instructions p{
    color: #ccd6f6;
    font-size: 14px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
}

.minigame{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.minigame canvas{
    background-color: #ccd6f6;
    opacity: 0.4;
}

.minigame h4{
    color: #37FF8B;
    font-size: 16px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
    margin: 10px 0;
    background-color: #1A2547;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.game-controls{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.game-controls button {
    margin: 10px 10px;
    background-color: #1A2547;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    opacity: 0.8;
    border-radius: 2px;
    cursor: pointer;

    color: #37FF8B;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
    font-size: 16px;
    border: none;
    box-sizing: border-box;
    filter: drop-shadow(0 0 2px #37FF8B)
}