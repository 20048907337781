@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap&family=Fira+Code&display=swap&family=Fira+Mono&display=swap');

.project-preview{
    height: 400px;
    margin-top: 65px;
    margin-bottom: 65px;
    width: 21%;
    background: linear-gradient(0deg, rgba(55, 0, 103, 0.5) 0%, #09143B 100%);
    border-radius: 3px;

    box-shadow: 5px 5px 20px 5px #3d3b3b88;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    overflow: hidden;
    transition: .5s all;

    display: flex;
    flex-direction: column;

    font-size: 18px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
    color: #ccd6f6;

    ::before{
        position: fixed;
        content: "";
        top: -10%;
        left: -100%;
        transform: rotate(-45deg);
        transition: .7s all;
      }
      &:hover{
        transform: scale(1.1);
        filter: brightness(1.1);
        ::before{
          filter: brightness(.5);
          top: -100%;
          left: 200%;
        }
      }

}

.aboutcard-preview{
    height: 85%;
    width: 80%;
    background: linear-gradient(0deg, rgba(55, 0, 103, 0.5) 0%, #09143B 100%);
    border-radius: 3px;

    box-shadow: 5px 5px 20px 5px #3d3b3b88;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    overflow: hidden;
    transition: .5s all;

    display: flex;
    flex-direction: column;

    font-size: 18px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
    color: #ccd6f6;

    ::before{
        position: fixed;
        content: "";
        top: -10%;
        left: -100%;
        transform: rotate(-45deg);
        transition: .7s all;
      }
      &:hover{
        transform: scale(1.1);
        filter: brightness(1.1);
        ::before{
          filter: brightness(.5);
          top: -100%;
          left: 200%;
        }
      }
}

.project-image{
    height: 35%;
    margin:3%;
    margin-top: 5%;
    object-fit: cover;
    border-radius: 3px;
}

.about-image{
    height: 50%;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 4%;
    object-fit: cover;
    border-radius: 3px;

}

.project-text-container{
    height: auto;
}

.project-text-container hr{
    margin-top: auto; /* Pushes the hr to the bottom */
    width: 80%;
    border: none;
    border-bottom: 1px solid #ada9a955;
}

.project-text-container h2{
    font-size: 16px;
    text-transform: uppercase;
    padding-left: 5%;
    color: #37FF8B;
}

.project-text-container .card-description{
    font-size: 12px;
    color: #a89ec9;
    padding-left: 5%;
}

.card-info{
    display: flex;
    align-items: center;
    
}

.card-info .card-date{
    width : 50%;
    height: 100%;
    padding-top: 1px;
    color: #a89ec9;
    text-decoration: none;
    .card-date-description{
        font-size: 14px;
    }
}

.card-icons{
    width : 50%;
    display: flex;
    justify-content: center;
    .card-image-icon{
        margin-left: 3px;
        margin-right: 3px;
        height: 25px;
        width: 25px;
    }
}
