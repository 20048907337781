@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap&family=Fira+Code&display=swap&family=Fira+Mono&display=swap');

.about-container{
}

.about-header {
    padding-top: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
  
.about-header button {
    flex: 1;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    cursor: pointer;

    height: auto;
    font-size: 16px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

    color: #ccd6f6;
    position: relative;
    text-transform: uppercase;
    background-color: transparent; 
}

.hover-text {
    position: absolute;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 2px;
    margin-left: 30%;
    margin-right: calc(30% + 10px);

    height: auto;
    font-size: 16px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

    box-sizing: border-box;
    color: #37FF8B;

    width: 0%;
    inset: 0;
    border-right: 3px solid #37FF8B;

    overflow: hidden;
    transition: 0.5s;
}
  
button:hover .hover-text {
    margin-left: 30%;
    margin-right: 30%;
    padding-left: 2px;
    width: 40%;
    filter: drop-shadow(0 0 20px #37FF8B)
}

button:hover .actual-text{
    transition: 0.5s;
    opacity: 0;
}

.about-main-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 2%;
  height: 80%;

}

.about-title{
  font-size: 24px;
  font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

  box-sizing: border-box;
  color: #37FF8B;
}

.about-description{
  font-size: 16px;
  font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

  box-sizing: border-box;
  color: #37FF8B;
  opacity: 0.5;
}

.about-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  margin: 2%;
  position: relative;
  width: 55%;
  height: 100%;
}

.about-more-info{
  position: relative;
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.about-more-info-container{
  height: 95%;
  width: 60%;
  background-color: #1A2547;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}