.App {
  background: linear-gradient(0deg, #0E2A85, #050B1F);
  height: 3520px;
  display: flex;
  flex-direction: column;
}

.section {
  opacity: 0; /* Start with sections hidden */
  transform: translateY(20px);
  transition: opacity 0.15s ease, transform 0.15s ease; /* Smooth transition for opacity and transform */
}

.section.active {
  opacity: 1;
  transform: translateY(0);
}


.cursor-glow {
  position: fixed;
  mix-blend-mode: screen;
  animation: glowing 1s infinite;
}

@keyframes glowing {
  from { box-shadow: 0 0 150px 100px rgb(37, 69, 53); }
  to { box-shadow: 0 0 150px 100px rgb(37, 69, 53); }
}