.section-header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
  
.section-header div {
    flex: 1;
    position: relative;
    text-transform: uppercase;
    background-color: transparent; 
    margin-bottom: 45px;
    margin-top: 45px;
    
}

.section-hover-text {
    height: auto;
    font-size: 16px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
    
    position: absolute;
    box-sizing: border-box;
    color: #37FF8B;
    border-right: 3px solid #37FF8B;
    margin-left: 30%;
    margin-right: 30%;
    padding-left: 10%;
    width: 40%;
    filter: drop-shadow(0 0 20px #37FF8B)
}

.section-hide{
    display: none;
}