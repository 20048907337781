@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap&family=Fira+Code&display=swap&family=Fira+Mono&display=swap');

.project-table-container {
    background-color: #1A2547;
    opacity: 0.7;
    width: 66%;
    height: 400px;
    margin: 2%;
    padding-left: 2%;
    padding-right: 2%;

    overflow-y: auto; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  
  .project-table-container table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
  }

  .project-table-container::-webkit-scrollbar {
    display: none; 
  }
  
  .experience-table-container {
    background-color: #1A2547;
    width: 66%;
    height: 600px;
    opacity: 0.7;
    margin: 2%;
    padding-left: 2%;
    padding-right: 2%;

    overflow-y: auto; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  
  .experience-table-container::-webkit-scrollbar {
    display: none; 
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  thead{
    position: sticky;
    top: 0;
    z-index: 2; 
  }

  thead tr{
    position: sticky;
    top: 0;
  }

  thead tr th {
    text-align: center;
    background-color: #1A2547;
    padding: 2%;

    height: auto;
    font-size: 16px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

    color: #37FF8B;
    position: sticky;
    top: 0;
  }

  thead tr th::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #ccd6f6;
    z-index: 1;
  }
  
  thead tr th {
    position: sticky;
    top: 0;
    z-index: 2; 
  }

  tbody tr{
    border-bottom: 1px solid #A6A3A3;
  }

  tbody tr td {
    border: 1px solid none;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 1%;
    padding-right: 1%;

    color: #ccd6f6;
    height: auto;
    font-size: 14px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
  }

  .table-card-icons{
    display: flex;
    justify-content: center;
    align-items: center;
    .table-card-image-icon{
        margin-left: 5px;
        margin-right: 5px;
        height: 25px;
        width: 25px;
    }
}